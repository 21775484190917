<template>
  <main
    class="page-route wrapper bg-gradient flex flex-col items-center gap-4 pb-4 md:py-24"
  >
    <alert_component v-if="loading_route" :loader="true">
      <template #message>
        {{ gt('In the blink of an eye it will be ready.') }}
      </template>
    </alert_component>

    <alert_component v-if="!loading_route && !route">
      <template #message>
        {{ gt('We could not find the route you are looking for.') }}
      </template>
    </alert_component>

    <div class="container !max-w-[480px]" v-if="!loading_route && route">
      <div
        class="cover"
        :style="{
          backgroundImage: `url(${image})`
        }"
      >
        <div class="flex h-fit absolute bottom-0 right-0 p-4 gap-2">
          <like_component :route_id="route.id" />
        </div>
      </div>

      <title_component>
        <template #title>{{ title }}</template>
        <template #subtitle>
          {{ description }}
        </template>
      </title_component>

      <units_component :route_id="route.id" />
    </div>
  </main>
</template>
<script setup>
  // Components
  import { defineAsyncComponent } from 'vue'

  const alert_component = defineAsyncComponent(
    () => import('@/components/commons/alert.vue')
  )

  const title_component = defineAsyncComponent(
    () => import('@/components/commons/title.vue')
  )

  const units_component = defineAsyncComponent(
    () => import('./components/units.vue')
  )

  const like_component = defineAsyncComponent(
    () => import('@/components/app/routes/buttons/likes.vue')
  )

  // ENV Enums
  import ENV from '@/../env.json'

  // Props
  const props = defineProps({
    route_id: {
      type: String,
      default: null
    }
  })

  // Helpers
  import get_words from '@/helpers/get_words'
  import url from '@/helpers/url'

  // Translation needs
  import { useI18n } from 'vue-i18n'
  import get_translation from '@/helpers/get_translation'
  const { t } = useI18n()
  const gt = (text, args) => get_translation(t, text, args)

  // Libs
  import { computed, ref, toRefs, onMounted } from 'vue'
  import Route from '@/methods/for_routes'
  import Plan from '@/methods/for_plans'

  // Stores
  import UserStore from '@/stores/user'
  import TopNavigationStore from '@/stores/top_navigation'

  // Variables
  const TopNavigation = TopNavigationStore()
  const User = UserStore()

  const query_params = new URLSearchParams(window.location.search)
  const { route_id } = toRefs(props)
  const route = ref(null)
  const plan = ref(null)
  const plan_id = query_params.get('plan_id')
  const loading_route = ref(true)
  const is_mobile = ref(false)
  const user_is_logged = User.is_logged

  // Computed variables
  const title = computed(() => {
    return get_words(route.value?.title, 8)
  })

  const description = computed(() => {
    return get_words(route.value?.description, 8)
  })

  const image = computed(() => {
    const image = route.value.images.find(
      (x) =>
        x.tag === ENV.enums.FileTags.ROUTE_COVER_19 ||
        x.tag === ENV.enums.FileTags.ROUTE_COVER_11
    )

    if (image) {
      return url({
        origin: 'memeonlyme.com',
        path: image.path,
        with_locale: false
      })
    }

    return null
  })

  // Methods
  const check_if_mobile = () => {
    const resolution = window.matchMedia('(max-width: 767px)')
    const on_change = (e) => {
      is_mobile.value = e.matches
    }

    resolution.addEventListener('change', on_change)
    on_change(resolution)
  }

  const get_route_by_slug = async () => {
    loading_route.value = true

    if (!route_id.value) {
      return
    }

    const { records } = await Route.get({
      with_properties: ['images'],
      specific: true,
      filters: {
        id: route_id.value
      }
    })

    route.value = records[0]
    loading_route.value = false
    TopNavigation.title = route.value.title
  }

  const get_plan_by_id = async () => {
    if (!user_is_logged) {
      return
    }

    if (!plan_id) {
      return
    }

    const { records } = await Plan.get({
      with_properties: [],
      specific: true,
      filters: {
        id: plan_id
      }
    })

    plan.value = records[0]
    TopNavigation.go_back = url({ path: `/plan/${plan.value.slug}` })
  }

  // Lifecycle
  onMounted(() => {
    TopNavigation.visible = true
    TopNavigation.title = gt('Route')

    check_if_mobile()
    get_route_by_slug()
    get_plan_by_id()
  })
</script>
<style lang="sass" scoped>
  @import "./styles/index.scss"
</style>
