<template>
  <div class="container !max-w-[480px]">
    <div class="flex flex-col gap-4">
      <form_login_component :on_response="on_response"></form_login_component>
    </div>
  </div>
</template>
<script setup>
  // Helpers
  import redirect_if_necessary from '@/helpers/redirect_if_necessary'

  // Components
  import form_login_component from '@/components/commons/forms/login.vue'

  // Methods
  const on_response = ({ success }) => {
    if (success) {
      redirect_if_necessary('/journey')
    }
  }
</script>
