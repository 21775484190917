<template>
  <div class="footer">
    <div class="footer-wrapper">
      <div class="footer-column">
        <div class="flex flex-col gap-2">
          <div class="title">{{ gt('Title') }}</div>

          <img
            :src="logo_text"
            class="w-[280px]"
            alt="memeonlyme"
            title="memeonlyme"
            aria-label="memeonlyme"
          />

          <div class="text-slate-500">
            {{ gt('Description') }}
          </div>

          <ul class="flex flex-col gap-2">
            <li>
              <a
                class="text-slate-500"
                :title="gt('Terms and Conditions')"
                :href="url({ path: '/terms' })"
                :aria-label="gt('Terms and Conditions')"
              >
                {{ gt('Terms and Conditions') }}
              </a>
            </li>
            <li>
              <a
                class="text-slate-500"
                :title="gt('How we use Cookies')"
                :href="url({ path: '/cookies' })"
                :aria-label="gt('How we use Cookies')"
              >
                {{ gt('How we use Cookies') }}
              </a>
            </li>
            <li>
              <a
                class="text-slate-500"
                :title="gt('Policies and Privacy')"
                :href="url({ path: '/privacy' })"
                :aria-label="gt('Policies and Privacy')"
              >
                {{ gt('Policies and Privacy') }}
              </a>
            </li>
            <li>
              <a
                class="text-slate-500"
                :title="gt('Consent')"
                :href="url({ path: '/consent' })"
                :aria-label="gt('Consent')"
              >
                {{ gt('Consent') }}
              </a>
            </li>
          </ul>

          <div class="text-slate-500">
            {{ gt('© {year} memeonlyme LLC', { year: 2024 }) }}
          </div>
        </div>
      </div>

      <div class="footer-column">
        <div class="flex flex-col gap-2">
          <div class="title">{{ gt('Tests') }}</div>
          <div class="m-0 flex flex-col gap-2">
            <alert_component v-if="loading_tests" :loader="true">
              <template #message>{{ gt('Loading') }}</template>
            </alert_component>

            <template v-if="tests.length > 0">
              <div class="flex flex-col" v-for="test in tests" :key="test">
                <a
                  class="font-medium"
                  :title="test.title"
                  :href="url({ path: `/post/${test.slug}` })"
                  :aria-label="test.title"
                >
                  {{ test.title }}
                  <i class="fi fi-rr-link-alt"></i>
                </a>
                <div class="text-slate-500 text-sm">
                  {{ test.description }}
                </div>
              </div>
            </template>
            <template v-else>
              <alert_component>
                <template #message>{{ gt('No data available') }}</template>
              </alert_component>
            </template>
          </div>
        </div>
      </div>

      <div class="footer-column">
        <div class="flex flex-col gap-2">
          <div class="title">{{ gt('Plans') }}</div>
          <div class="m-0 flex flex-col gap-2">
            <alert_component v-if="loading_plans" :loader="true">
              <template #message>{{ gt('Loading') }}</template>
            </alert_component>

            <template v-if="plans.length > 0">
              <div class="flex flex-col" v-for="plan in plans" :key="plan">
                <a
                  class="font-medium"
                  :title="plan.title"
                  :href="url({ path: `/post/${plan.slug}` })"
                  :aria-label="plan.title"
                >
                  {{ plan.title }}
                  <i class="fi fi-rr-link-alt"></i>
                </a>
                <div class="text-slate-500 text-sm">
                  {{ plan.description }}
                </div>
              </div>
            </template>
            <template v-else>
              <alert_component>
                <template #message>{{ gt('No data available') }}</template>
              </alert_component>
            </template>
          </div>
        </div>
      </div>

      <div class="footer-column">
        <div class="flex flex-col gap-2 w-full">
          <div class="title">{{ gt('Find Us') }}</div>
          <ul class="social-sharing">
            <li v-for="network in networks" :key="network.name">
              <a
                :title="network.name"
                :href="network.url"
                :alt="network.name"
                class="no-underline text-xl"
              >
                <i :class="[network.icon]"></i>
              </a>
            </li>
          </ul>

          <div class="title">{{ gt('Download the App') }}</div>
          <div class="buttons flex flex-wrap w-full gap-2">
            <a
              v-for="store in stores"
              :key="store.name"
              :title="
                gt('Download from :store_name', {
                  store_name: store.name
                })
              "
              target="_blank"
              :href="store.url"
              :class="['btn', store.btn_class]"
            >
              <i :class="['lh-1', 'fi', 'store.icon']"></i>
              {{ store.name }}
            </a>
          </div>

          <div class="title">{{ gt('Change the website language') }}</div>
          <ul class="flex flex-col gap-2">
            <li v-if="$i18n.locale !== 'es'">
              <a
                :title="gt('Change language')"
                :href="url({ path: '/', with_locale: false })"
                :alt="gt('Change language')"
                :aria-label="gt('Change language')"
              >
                {{ gt('Change to {language}', { language: 'Spanish' }) }}
              </a>
            </li>
            <li v-if="$i18n.locale !== 'en'">
              <a
                :title="gt('Change language')"
                :href="url({ path: '/', with_locale: false })"
                :alt="gt('Change language')"
                :aria-label="gt('Change language')"
              >
                {{ gt('Change to {language}', { language: 'English' }) }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  // Components
  import { defineAsyncComponent } from 'vue'

  const alert_component = defineAsyncComponent(
    () => import('@/components/commons/alert.vue')
  )

  // ENV Variables
  import ENV from '@/../env.json'

  // Helpers
  import url from '@/helpers/url'
  import get_locale from '@/helpers/get_locale'

  // Translation needs
  import { useI18n } from 'vue-i18n'
  import get_translation from '@/helpers/get_translation'
  const { t } = useI18n()
  const gt = (text, args) => get_translation(t, text, args)

  // Images
  import logo_text from '@/assets/logos/logo-text-black.png'

  // Lib
  import { ref, onMounted } from 'vue'
  import Post from '@/methods/for_posts'

  // Variables
  const tests = ref([])
  const plans = ref([])

  const loading_tests = ref(false)
  const loading_plans = ref(false)

  const networks = ENV.networks
  const stores = ENV.links.stores

  // Methods
  const get_plans = async function (args = {}) {
    loading_plans.value = true

    args.per_page = 3
    args.filters = {
      search: 'plan',
      language: get_locale()
    }

    const { records } = await Post.get(args)

    plans.value = records
    loading_plans.value = false
  }

  const get_tests = async function (args = {}) {
    loading_tests.value = true
    args.per_page = 3
    args.filters = {
      search: 'test',
      language: get_locale()
    }

    const { records } = await Post.get(args)

    tests.value = records
    loading_tests.value = false
  }

  // Lifecycle
  onMounted(() => {
    get_tests()
    get_plans()
  })
</script>
