<template>
  <div class="container !max-w-[480px]">
    <div class="flex flex-col gap-4">
      <form_recover_component
        :on_response="on_response"
      ></form_recover_component>
    </div>
  </div>
</template>
<script setup>
  // Helpers
  import url from '@/helpers/url'

  // Components
  import form_recover_component from '@/components/commons/forms/recover.vue'

  // Methods
  const on_response = ({ success }) => {
    if (success) {
      const params = new URLSearchParams(window.location.search)
      const redirect_to = params.get('redirect_to') || '/routes'

      window.location.href = url({
        path: redirect_to
      })
    }
  }
</script>
