<template>
  <transition
    enter-active-class="animate__animated animate__faster animate__fadeInUp"
    leave-active-class="animate__animated animate__faster animate__fadeOut"
  >
    <div class="mobile-navigation" v-if="is_visible && user_is_logged">
      <div class="content">
        <div
          :class="['item', { active: active_menu === 'journey' }]"
          @click="go_to('/journey')"
        >
          <div class="fi-content">
            <i class="fi fi-rr-house-blank"></i>
          </div>

          <span>{{ gt('Home') }}</span>
        </div>
        <div
          :class="['item', { active: active_menu === 'plan' }]"
          @click="go_to(`/plan/${plan.slug}`)"
        >
          <div class="fi-content">
            <i class="fi fi-rr-bolt"></i>
          </div>

          <span>{{ gt('Continue') }}</span>
        </div>
        <div :class="['item']" @click="go_to('/routes')">
          <div class="fi-content">
            <i class="fi fi-rr-search"></i>
          </div>

          <span>{{ gt('Search') }}</span>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
  // Libs
  import { ref, onMounted, onUnmounted, computed, watch } from 'vue'
  import Plan from '@/methods/for_plans'

  // Helpers
  import url from '@/helpers/url'

  // Stores
  import UserStore from '@/stores/user'
  import BottomNavigationStore from '@/stores/bottom_navigation'

  // Translation needs
  import { useI18n } from 'vue-i18n'
  import get_translation from '@/helpers/get_translation'
  const { t } = useI18n()
  const gt = (text, args) => get_translation(t, text, args)

  // Variables
  const User = UserStore()
  const Navigation = BottomNavigationStore()

  const last_scroll_position = ref(0)
  const { is_logged: user_is_logged } = User
  const plan = ref({})

  // Computed properties
  const is_visible = computed(() => Navigation.visible)
  const active_menu = computed(() => Navigation.active_menu)

  // Methods
  const get_plans = async function () {
    if (!user_is_logged) {
      return
    }

    const { success, plans } = await Plan.my_plans()

    if (success) {
      plan.value = get_plan(plans)
    }
  }

  const get_plan = function (plans) {
    let plan = plans.find((plan) => plan.starter === 1)

    if (plan?.progress === 100) {
      plan = plans[plans.length - 1]
    }

    return plan
  }

  const handle_scroll = () => {
    const current_scroll = window.scrollY
    const open = current_scroll <= last_scroll_position.value

    if (!Navigation.disabled) {
      Navigation.visible = open
    }

    last_scroll_position.value = current_scroll
  }

  const go_to = (path, params = {}) => {
    window.location.href = url({ path, params })
  }

  // Lifecycle
  onMounted(async () => {
    window.addEventListener('scroll', handle_scroll)
    await get_plans()

    if (user_is_logged && is_visible.value) {
      const wrapper = document.querySelector('.wrapper')

      if (wrapper) {
        wrapper.classList.add('pb-[85px]')
      }
    }
  })

  onUnmounted(() => {
    window.removeEventListener('scroll', handle_scroll)
  })
</script>

<style lang="scss" scoped>
  @use './styles/navigation.scss';
</style>
