// Libs
import { defineStore } from 'pinia'
import { ref } from 'vue'

export default defineStore('bottom_navigation', () => {
  const visible = ref(true)
  const disabled = ref(false)
  const active_menu = ref(null)

  return {
    visible,
    disabled,
    active_menu
  }
})
