<template>
  <div class="component-posts container !max-w-screen-sm">
    <div class="flex flex-col gap-4 w-full">
      <title_component>
        <template #title>{{ gt('Articles') }}</template>
        <template #subtitle>
          {{ gt('Here are all our items available for you.') }}
        </template>
      </title_component>

      <loading_component v-if="loading"></loading_component>

      <alert_component v-if="!loading && posts.length === 0">
        <template #message>
          {{ gt('We could not find what you are looking for') }}
        </template>
      </alert_component>

      <div class="flex flex-col gap-2">
        <post_component
          v-for="(post, index) in posts"
          :key="post.id"
          :post="post"
          :post_style="index === 0 ? 'full' : 'condensed'"
        />
      </div>

      <div
        class="pagination flex gap-2 justify-between"
        v-if="with_pagination && posts_pagination.total_pages > 1"
      >
        <div class="btn btn-light items-center" @click="go_to_page(-1)">
          <i class="fi fi-rr-angle-double-small-left"></i>
          {{ gt('Previous') }}
        </div>
        <div class="btn btn-light items-center" @click="go_to_page(1)">
          {{ gt('Next') }}
          <i class="fi fi-rr-angle-double-small-right"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  // Components
  import { defineAsyncComponent } from 'vue'

  const title_component = defineAsyncComponent(
    () => import('@/components/commons/title.vue')
  )

  const loading_component = defineAsyncComponent(
    () => import('@/components/commons/loading.vue')
  )

  const alert_component = defineAsyncComponent(
    () => import('@/components/commons/alert.vue')
  )

  // Translation needs
  import { useI18n } from 'vue-i18n'
  import get_translation from '@/helpers/get_translation'
  const { t } = useI18n()
  const gt = (text, args) => get_translation(t, text, args)

  // Components
  import post_component from './post.vue'

  // Libs
  import { toRefs } from 'vue'

  // Props
  const props = defineProps({
    posts: {
      default: {}
    },

    posts_pagination: {
      default: {}
    },

    loading: {
      default: false
    },

    on_go_to_page: {
      default: null
    },

    with_pagination: {
      default: false
    }
  })

  // Variables
  const { posts, posts_pagination, loading, on_go_to_page, with_pagination } =
    toRefs(props)

  // Methods
  const go_to_page = async function (to) {
    const go_to_page = posts_pagination.value.page + to

    if (go_to_page > posts_pagination.value.total_pages || go_to_page <= 0) {
      return
    }

    if (with_pagination && on_go_to_page) {
      await on_go_to_page.value({
        page: go_to_page
      })
    }
  }
</script>
