<template>
  <form :class="[...(!clean ? ['card', 'p-4'] : [])]" @submit.prevent="login()">
    <div class="w-full flex flex-col gap-2">
      <input_component
        :settings="form.inputs.email"
        @update:value="form.inputs.email.value = $event"
      />

      <input_component
        :settings="form.inputs.password"
        @update:value="form.inputs.password.value = $event"
      />

      <div class="flex justify-between content-between w-100">
        <div class="grow">
          <input_component
            :settings="form.inputs.remember_me"
            @update:value="form.inputs.remember_me.value = $event"
          />
        </div>
        <div class="flex grow items-center justify-end text-right">
          <a class="font-medium" :href="url({ path: '/recover/password' })">
            {{ gt('Recover your password') }}
          </a>
        </div>
      </div>

      <div v-if="form.error" class="alert alert-light">
        <i class="fi fi-rr-info"></i> {{ form.error }}
      </div>

      <button_component
        :classes="['btn', 'btn-dark', 'grow']"
        :loading="form.is_loading"
        :submittable="true"
      >
        <i class="fi fi-rr-sign-in-alt"></i>
        {{ gt('Sign In') }}
      </button_component>

      <p class="block text-center text-slate-500">
        {{ gt('Or') }}
      </p>

      <div class="buttons flex gap-2">
        <button_component
          :classes="['btn', 'btn-google', 'w-full']"
          @click="login('google')"
          :loading="login_with_google_is_loading"
        >
          <i class="fi fi-brands-google"></i>
          {{ gt('Google') }}
        </button_component>

        <button_component
          :classes="['btn', 'btn-dark', 'w-full']"
          @click="login('apple')"
          :loading="login_with_apple_is_loading"
          v-if="show_apple_login"
        >
          <i class="fi fi-brands-apple"></i>
          {{ gt('Apple') }}
        </button_component>
      </div>

      <div class="flex flex-wrap gap-2 items-center justify-center">
        <a
          :href="url({ path: '/terms' })"
          target="_blank"
          class="text-slate-800"
        >
          {{ gt('Terms and Conditions') }}
        </a>

        <a
          :href="url({ path: '/privacy' })"
          target="_blank"
          class="text-slate-800"
        >
          {{ gt('Policies and Privacy') }}
        </a>
      </div>
    </div>
  </form>

  <div class="flex flex-col" v-if="with_sign_up">
    <p class="text-slate-500 text-center">
      {{ gt('Do not have an account?') }}
      <a title="Regístrate" :href="url({ path: '/register' })">
        {{ gt('Sign Up Here') }}
      </a>
    </p>
  </div>
</template>

<script setup>
  // Components
  import { defineAsyncComponent } from 'vue'

  const button_component = defineAsyncComponent(
    () => import('@/components/commons/button.vue')
  )

  const input_component = defineAsyncComponent(
    () => import('@/components/commons/input.vue')
  )

  // Stores
  import UserStore from '@/stores/user'

  // Helpers
  import url from '@/helpers/url'
  import get_device_environment from '@/helpers/get_device_environment'
  import get_form_state from '@/helpers/get_form_state'
  import login_with from './methods/commons/login_with'

  // Translation needs
  import { useI18n } from 'vue-i18n'
  import get_translation from '@/helpers/get_translation'
  const { t } = useI18n()
  const gt = (text, args) => get_translation(t, text, args)

  // Libs
  import { toRefs, ref, computed, onMounted } from 'vue'
  import Authentication from '@/methods/for_authentication'

  // Stores
  import AppStore from '@/stores/app'

  // Props
  const props = defineProps({
    with_sign_up: {
      type: Boolean,
      default: true
    },

    clean: {
      type: Boolean,
      default: false
    },

    on_response: {
      default: null
    }
  })

  // Variables
  const { on_response } = toRefs(props)
  const App = AppStore()
  const login_with_google_is_loading = ref(false)
  const login_with_apple_is_loading = ref(false)
  const form = get_form_state('login', [
    {
      label: gt('Email'),
      name: 'email',
      type: 'text'
    },
    {
      label: gt('Password'),
      name: 'password',
      type: 'password',
      icon: {
        name: 'fi-rr-eye',
        onclick: {
          callback: () => {
            form.inputs.password.type =
              form.inputs.password.type === 'password' ? 'text' : 'password'
          }
        }
      }
    },
    {
      label: gt('Keep me logged in'),
      name: 'remember_me',
      type: 'checkbox',
      value: true
    },
    {
      label: gt('Authorization'),
      name: 'authorization',
      type: 'hidden'
    },
    {
      label: gt('UserId'),
      name: 'user_id',
      type: 'hidden'
    }
  ])

  const show_apple_login = computed(() => {
    const { os } = get_device_environment()
    return os === 'ios' || os === 'macos'
  })

  const login = async (provider = null) => {
    let response = null
    let name = null
    let email = null
    let google_id = null
    let apple_id = null
    let photo = null

    form.is_loading = true

    if (provider) {
      form.inputs.email.value = ''
      form.inputs.email.default = ''

      form.inputs.password.value = ''
      form.inputs.password.default = ''
    }

    if (provider === 'google') {
      login_with_google_is_loading.value = true
      response = await login_with('google')
    }

    if (provider === 'apple') {
      login_with_apple_is_loading.value = true
      response = await login_with('apple')
    }

    if (provider) {
      if (!response) {
        form.is_loading = false
        login_with_google_is_loading.value = false
        login_with_apple_is_loading.value = false
        return
      }

      name = response.name
      email = response.email
      photo = response.photo

      if (provider === 'google') {
        google_id = response.uid
      }

      if (provider === 'apple') {
        apple_id = response.uid
      }
    }

    const { success, errors, message, user } =
      await Authentication.authenticate({
        $form: form,
        append: {
          name,
          email,
          google_id,
          apple_id,
          photo,
          logged_by: provider
        }
      })

    if (success && user) {
      UserStore().update(user)
    }

    if (on_response.value) {
      on_response.value({ success, errors, message, user })
    }

    form.is_loading = false
    login_with_google_is_loading.value = false
    login_with_apple_is_loading.value = false
  }

  const auth_by_token = function () {
    const params = new URLSearchParams(window.location.search)
    const user_id = params.get('user_id')
    const authorization = params.get('authorization')

    if (user_id && authorization) {
      App.notification({
        title: gt('Hold on a sec...'),
        message: gt('We are logging you in so you can continue.')
      })

      form.inputs.user_id.value = user_id
      form.inputs.authorization.value = authorization
      login()
    }
  }

  onMounted(() => {
    auth_by_token()
  })
</script>
