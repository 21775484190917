<template>
  <div class="container !max-w-[480px] appointments">
    <title_component :size="'large'">
      <template #title> {{ gt('Appointments') }} </template>
      <template #subtitle>
        {{ gt('View and manage your appointments') }}
      </template>
    </title_component>

    <div class="tabs">
      <span
        :class="['tab', { active: tab === 'next_to' }]"
        @click="tab = 'next_to'"
      >
        {{ gt('Ongoing') }}
      </span>

      <span
        :class="['tab', { active: tab === 'make_an_appointment' }]"
        @click="tab = 'make_an_appointment'"
      >
        {{ gt('Make one') }}
      </span>

      <span
        :class="['tab', { active: tab === 'appointments' }]"
        @click="tab = 'appointments'"
      >
        {{ gt('History') }}
      </span>
    </div>

    <make_an_appointment_component v-if="tab === 'make_an_appointment'" />

    <appointments_components
      :title="gt('Ongoing')"
      :subtitle="gt(`Let's check your next appointments`)"
      :statuses="[STATUSES.PENDING, STATUSES.CONFIRMED]"
      v-if="tab === 'next_to'"
    />

    <appointments_components
      :title="gt('History')"
      :subtitle="gt(`Let's check your appointments history`)"
      :statuses="[
        STATUSES.CANCELLED,
        STATUSES.ATTENDED,
        STATUSES.FINISHED_BY_OVERTIME
      ]"
      v-if="tab === 'appointments'"
    />
  </div>
</template>

<script setup>
  // ENV Variables
  import ENV from '@/../env.json'

  // Components
  import {
    defineAsyncComponent,
    ref,
    onBeforeMount,
    watch,
    onMounted
  } from 'vue'

  const title_component = defineAsyncComponent(
    () => import('@/components/commons/title.vue')
  )

  const make_an_appointment_component = defineAsyncComponent(
    () => import('./tabs/make_an_appointment/index.vue')
  )

  const appointments_components = defineAsyncComponent(
    () => import('./tabs/appointments/index.vue')
  )

  // Translation needs
  import { useI18n } from 'vue-i18n'
  import get_translation from '@/helpers/get_translation'
  const { t } = useI18n()
  const gt = (text, args) => get_translation(t, text, args)

  // Helpers
  import url from '@/helpers/url'

  // Stores
  import UserStore from '@/stores/user'
  import BottomNavigationStore from '@/stores/bottom_navigation'
  import TopNavigationStore from '@/stores/top_navigation'

  // Variables
  const User = UserStore()
  const BottomNavigation = BottomNavigationStore()
  const TopNavigation = TopNavigationStore()

  const { is_logged } = User
  const query_params = new URLSearchParams(window.location.search)
  const tab = ref(query_params.get('tab') || 'appointments')

  const STATUSES = ENV.enums.AppointmentStatuses

  // Watchers
  watch(tab, (newTab) => {
    const current_url = new URL(window.location.href)
    current_url.searchParams.set('tab', newTab)
    window.history.pushState({}, '', current_url)
  })

  // Lifecycle
  onBeforeMount(() => {
    if (!is_logged) {
      window.location.href = url({
        path: '/login',
        params: { redirect_to: window.location.pathname }
      })
    }
  })

  onMounted(() => {
    TopNavigation.title = gt('Appointments')
    TopNavigation.go_back = url({ path: '/journey' })
    BottomNavigation.visible = true
    BottomNavigation.active_menu = 'journey'
  })
</script>
<style lang="scss" scoped>
  @use './styles/index.scss';
</style>
