// Libs
import { defineStore } from 'pinia'
import { ref } from 'vue'

export default defineStore('top_navigation', () => {
  const visible = ref(true)
  const disabled = ref(false)
  const title = ref(null)
  const go_back = ref(null)
  const background = ref('light')
  const opacity = ref(0.9)

  return {
    visible,
    disabled,
    title,
    background,
    opacity
  }
})
