<template>
  <div class="card p-4">
    <button_component
      :classes="['btn', 'btn-danger', 'w-full']"
      :loading="loading"
      @click="delete_notifications()"
    >
      <i class="fi fi-rr-trash"></i>
      {{ gt('Stop receiving notifications') }}
    </button_component>
  </div>
</template>
<script setup>
  // Components
  import { defineAsyncComponent } from 'vue'

  const button_component = defineAsyncComponent(
    () => import('@/components/commons/button.vue')
  )

  // Enums
  import ENV from '@/../env.json'

  // Libs
  import { toRefs, ref } from 'vue'
  import User from '@/methods/for_users'

  // Translation needs
  import { useI18n } from 'vue-i18n'
  import get_translation from '@/helpers/get_translation'
  const { t } = useI18n()
  const gt = (text, args) => get_translation(t, text, args)

  // Stores
  import AppStore from '@/stores/app'

  // Props
  const props = defineProps({
    user_id: {
      default: null
    },

    on_response: {
      default: null
    }
  })

  // Variable
  const { user_id, on_response } = toRefs(props)
  const loading = ref(false)
  const App = AppStore()

  // Methods
  const delete_notifications = async () => {
    loading.value = true

    const { success, message } = await User.remove_notifications({
      id: user_id.value
    })

    App.notification({
      level: success
        ? ENV.enums.AlertLevels.SUCCESS
        : ENV.enums.AlertLevels.ERROR,
      title: gt('Hey!'),
      message
    })

    if (on_response.value) {
      on_response.value({ success, message })
    }

    loading.value = false
  }
</script>
