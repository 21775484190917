export default function () {
  const supported_locales = ['en']
  const url_first_segment = window.location.pathname.split('/')[1]

  if (supported_locales.includes(url_first_segment)) {
    return url_first_segment
  }

  return 'es'
}
