<template>
  <div class="container !max-w-[480px]">
    <transition>
      <div class="flex flex-col gap-4 w-full" v-if="plan">
        <div class="flex flex-col gap-2">
          <title_component :size="'large'">
            <template #title> {{ plan.title }} </template>
            <template #subtitle> {{ plan.description }} </template>
          </title_component>

          <div class="flex w-full" v-if="progress > 0">
            <div class="progress">
              <div
                class="progress-bar"
                :style="{ width: `${progress}%` }"
              ></div>
            </div>
          </div>

          <div class="flex flex-col gap-2">
            <button_component :classes="['btn', 'btn-dark', 'w-fit']">
              <i class="fi fi-rr-play"></i>
              {{ gt('Continue') }}
            </button_component>
          </div>
        </div>

        <div class="routes">
          <div
            v-for="route in routes"
            :key="route"
            class="route"
            @click="go_to(route)"
          >
            <div
              v-if="route.next_to_unlock === true && route.locked"
              class="flex flex-col text-sm text-slate-500"
              id="next_to_unlock"
            >
              {{ gt('Next to unlock ↓') }}
            </div>

            <!-- Alerts -->
            <template v-if="route.next_to_unlock === true">
              <need_appointment_component
                v-if="
                  PLAN_LOCK_CODES.NEED_APPOINTMENT === route.lock_code ||
                  PLAN_LOCK_CODES.NEED_FINISHED_APPOINTMENT === route.lock_code
                "
                :code="route.lock_code"
                :message="route.lock_message"
                :route="route.route"
                :plan="plan"
              />
            </template>

            <!-- Route -->
            <div
              :class="[
                'route-wrapper',
                route.locked && !route.next_to_unlock ? 'locked' : '',
                route.locked && route.next_to_unlock ? 'completed' : '',
                route.progress === 100 ? 'completed' : ''
              ]"
            >
              <div
                v-if="route.active === true"
                class="image"
                :style="{
                  backgroundImage: `url(${get_image_from_array(route.images)})`
                }"
              ></div>

              <div class="p-4 flex flex-col gap-4">
                <div class="content">
                  <div class="info">
                    <h3 class="title">
                      {{ route.route.title }}
                    </h3>
                    <p class="description">{{ route.route.description }}</p>
                  </div>
                  <div class="r-button">
                    <i class="fi fi-rr-play" v-if="!route.locked"></i>
                    <i class="fi fi-rr-lock" v-if="route.locked"></i>
                  </div>
                </div>

                <div class="flex w-full">
                  <div class="progress">
                    <div
                      class="progress-bar"
                      :style="{ width: `${route.progress}%` }"
                    ></div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Alerts -->
            <template v-if="route.next_to_unlock === true">
              <blocked_route_component
                v-if="
                  PLAN_LOCK_CODES.ROUTE_NOT_COMPLETED === route.lock_code ||
                  PLAN_LOCK_CODES.WAITING_TIME === route.lock_code
                "
                :message="route.lock_message"
              />
            </template>

            <div
              v-if="route.locked && route.setting.need_finished_appointment"
              class="legend"
            >
              <span class="icon">
                <i class="fi fi-rr-calendar-exclamation"></i>
              </span>

              <span class="text-slate-500">
                {{ gt('You will need an appointment for this route.') }}
              </span>
            </div>
          </div>
        </div>

        <div class="flex flex-col gap-2">
          <a
            :href="url({ path: '/contact' })"
            :class="['btn', 'btn-condensed', 'btn-dark', 'w-fit']"
          >
            <i class="fi fi-rr-life-ring"></i>
            {{ gt('Do you have any issues?') }}
          </a>

          <a
            :href="url({ path: '/feedback' })"
            :class="['btn', 'btn-condensed', 'btn-link', 'w-fit']"
          >
            <i class="fi fi-rr-message-quote"></i>
            {{ gt('Suggest improvements') }}
          </a>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
  // ENV Variables
  import ENV from '@/../env.json'

  // Components
  import { defineAsyncComponent, onBeforeMount, onMounted, ref } from 'vue'

  const title_component = defineAsyncComponent(
    () => import('@/components/commons/title.vue')
  )

  const button_component = defineAsyncComponent(
    () => import('@/components/commons/button.vue')
  )

  const need_appointment_component = defineAsyncComponent(
    () => import('./alerts/need_appointment.vue')
  )

  const blocked_route_component = defineAsyncComponent(
    () => import('./alerts/blocked_route.vue')
  )

  // Helpers
  import url from '@/helpers/url'
  import scroll_to_element from '@/helpers/scroll_to_element'

  // Translation needs
  import { useI18n } from 'vue-i18n'
  import get_translation from '@/helpers/get_translation'
  const { t } = useI18n()
  const gt = (text, args) => get_translation(t, text, args)

  // Libs
  import Plan from '@/methods/for_plans'

  // Stores
  import UserStore from '@/stores/user'
  import BottomNavigationStore from '@/stores/bottom_navigation'
  import TopNavigationStore from '@/stores/top_navigation'

  // Methods
  const get_plan_slug = function () {
    const path = window.location.pathname.split('/')
    return path[path.length - 1]
  }

  // Variables
  const PLAN_LOCK_CODES = ENV.enums.PlanLockCodes

  const User = UserStore()
  const BottomNavigation = BottomNavigationStore()
  const TopNavigation = TopNavigationStore()

  const { is_logged } = User
  const slug = get_plan_slug()
  const plan = ref(null)
  const routes = ref([])
  const progress = ref(0)

  // Methods
  const go_to = function (x) {
    const { route, locked } = x

    if (locked) {
      return
    }

    window.location.href = url({
      path: `/route/${route.slug}`,
      params: { plan_id: plan.value.id }
    })
  }

  const get_image_from_array = (images) => {
    if (!images || images.length === 0) {
      return ''
    }

    return url({ path: images[0].path, with_locale: false })
  }

  const get_plan_by_slug = async function () {
    const { success, records } = await Plan.get({
      filters: {
        specific: true,
        slug
      }
    })

    if (!success || !records) {
      window.location.href = url({ path: '/journey' })
    }

    plan.value = records[0]
    TopNavigation.title = plan.value.title
  }

  const get_my_plan_status = async function () {
    const { status } = await Plan.my_plan_status({
      filters: {
        plan_id: plan.value.id
      }
    })

    progress.value = status.plan.progress
    routes.value = status.routes

    if (routes.value.length > 1) {
      if (!(!routes.value[0]?.locked && routes.value[1].locked)) {
        scroll_to_element('#next_to_unlock', 1500)
      }
    }
  }

  // Lifecycle
  onBeforeMount(() => {
    TopNavigation.title = gt('My Plan')
    TopNavigation.go_back = url({ path: '/journey' })
    BottomNavigation.active_menu = 'plan'

    if (!is_logged) {
      window.location.href = url({
        path: '/login',
        params: { redirect_to: window.location.pathname }
      })
    }
  })

  onMounted(async () => {
    await get_plan_by_slug()
    await get_my_plan_status()
  })
</script>

<style lang="sass" scoped>
  @import "./styles/index.scss"
</style>
