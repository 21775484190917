// Enums
import env from '@/../env.json'

// Helpers
import get_locale from '@/helpers/get_locale'

// Stores
import AppStore from '@/stores/app'

export default class Request {
  notify = null
  protected_by_captcha = ['/v1/user']

  App = AppStore()

  make_headers = async (request) => {
    const user = JSON.parse(localStorage.getItem('user') || '{}')
    const session_id = localStorage.getItem('session_id')
    const token = user?.token || null
    const csrf_token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute('content')

    let recaptcha_token = null
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    let locale = get_locale()

    const bypass = sessionStorage.getItem('X-RECAPTCHA-TOKEN')

    // Request reCAPTCHA token for non-GET requests
    if (request.method !== 'get') {
      if (bypass) {
        recaptcha_token = bypass
      } else {
        if (this.protected_by_captcha.includes(request.target)) {
          try {
            recaptcha_token = await this.get_recaptcha_token('general')
          } catch (error) {
            console.error('Error obtaining reCAPTCHA token:', error)
          }
        }
      }
    }

    const headers = {
      Accept: 'application/json',
      Authorization: token,
      'X-RECAPTCHA-TOKEN': recaptcha_token,
      'X-CSRF-TOKEN': csrf_token,
      timezone,
      locale,
      session_id
    }

    Object.keys(headers).forEach((x) => {
      const value = headers[x]

      if (value === null || value === undefined) {
        delete headers[x]
      }
    })

    return headers
  }

  make_url = (target, params = {}) => {
    let url = target

    if (params && Object.keys(params).length > 0) {
      let elements = {}

      Object.keys(params).forEach((key) => {
        let value = params[key]

        if (value !== null && value !== undefined && value !== '') {
          if (typeof value === 'boolean') {
            value = Number(value)
          }

          elements[key] = value
        }
      })

      const query = new URLSearchParams(elements).toString()
      url += `?${query}`
    }

    return url
  }

  make_request = async (
    method = 'get',
    target = '/',
    body = {},
    params = null
  ) => {
    let url = null
    let utms = {}

    env.utm_variables.forEach((key) => {
      utms[key] = localStorage.getItem(key) || null
    })

    if (method === 'get') {
      url = this.make_url(env.api.url + target, {
        ...params,
        ...utms
      })
    } else {
      url = this.make_url(env.api.url + target, params)
    }

    const form_data = new FormData()

    for (const key in { ...body, ...utms }) {
      let value = body[key]

      if (value !== null && value !== undefined && value !== '') {
        if (typeof value === 'boolean') {
          value = Number(value)
        } else if (value instanceof Date) {
          value = value.toISOString()
        }

        form_data.append(key, value)
      }
    }

    const headers = await this.make_headers({
      target,
      method
    })

    const options = {
      method,
      headers,
      body: form_data
    }

    if (method === 'get') {
      delete options.body
    }

    try {
      const response = await fetch(url, options)
      return await response.json()
    } catch (error) {
      return {
        success: false,
        message: error
      }
    }
  }

  post = async (target, body = false) => {
    return await this.make_request('post', target, body, null)
  }

  get = async (target, params) => {
    return await this.make_request('get', target, {}, params)
  }

  delete = async (target, body) => {
    return await this.make_request('delete', target, {}, body)
  }

  get_recaptcha_token = (action) => {
    return new Promise((resolve, reject) => {
      if (!window.grecaptcha) {
        return reject(new Error('reCAPTCHA not loaded'))
      }

      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute('6LeBYYMqAAAAAGrLsh0UUuZr2s1TvQj4jpZO1hZz', { action })
          .then((token) => {
            resolve(token)
          })
          .catch((err) => {
            reject(err)
          })
      })
    })
  }
}
