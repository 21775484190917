// Wrappers
import StoreWrapper from './helpers/wrappers/store'

// Services
import UserService from '@/services/user'

export default {
  async authenticate({ $form, append }) {
    return await StoreWrapper(new UserService().authenticate, {
      $form,
      append
    })
  }
}
