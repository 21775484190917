// ENV Variables
import ENV from '@/../env.json'

// Libraries
import {
  GoogleAuthProvider,
  signInWithPopup,
  OAuthProvider
} from 'firebase/auth'

import { FirebaseAuthentication } from '@capacitor-firebase/authentication'

// Helpers
import setup_firebase from '@/helpers/setup_firebase'
import get_device_environment from '@/helpers/get_device_environment'

// Store
import AppStore from '@/stores/app'

const do_login_as_mobile = async (login_with) => {
  if (login_with === 'google') {
    try {
      return await FirebaseAuthentication.signInWithGoogle()
    } catch (error) {
      console.error(error)
      return null
    }
  }

  if (login_with === 'apple') {
    try {
      return await FirebaseAuthentication.signInWithApple()
    } catch (error) {
      console.error(error)
      return null
    }
  }
}

const do_login_as_web = async (login_with) => {
  const { auth } = setup_firebase()
  const App = AppStore()

  let provider = null

  if (login_with === 'google') {
    try {
      provider = new GoogleAuthProvider()
    } catch (error) {
      console.error(error)
      return null
    }
  } else if (login_with === 'apple') {
    try {
      provider = new OAuthProvider('apple.com')
    } catch (error) {
      console.error(error)
      return null
    }
  } else {
    App.notification({
      title: 'Oops!',
      message: 'We have a problem with the login process',
      level: ENV.enums.AlertLevels.DANGER
    })
    return null
  }

  try {
    return await signInWithPopup(auth, provider)
  } catch (error) {
    App.notification({
      title: 'Oops!',
      message: 'We have a problem with the login process',
      level: ENV.enums.AlertLevels.DANGER
    })

    console.error(error)
    return null
  }
}

export default async function (login_with = 'google') {
  const App = AppStore()
  const { is_webview } = get_device_environment()

  let response = null

  if (is_webview) {
    response = await do_login_as_mobile(login_with)
  } else {
    response = await do_login_as_web(login_with)
  }

  if (!response) {
    App.notification({
      title: 'Oops!',
      message: 'We have a problem with the login process',
      level: ENV.enums.AlertLevels.DANGER
    })
    return null
  }

  const user = response?.user || {}
  const { displayName: name, email, uid, photoURL: photo } = user

  return {
    name,
    email,
    uid,
    photo
  }
}
