import { defineStore } from 'pinia'
import { computed, ref, reactive } from 'vue'

export default defineStore('user', () => {
  const user = reactive({})

  const is_logged = computed(() => {
    return user && user?.id && new Date(user?.token_expires_at) > new Date()
  })

  function update(_) {
    localStorage.setItem('user', JSON.stringify(_))
    Object.assign(user, _)
  }

  function restore() {
    let stored_user = localStorage.getItem('user')

    if (user) {
      Object.assign(user, JSON.parse(stored_user))

      if (user?.token_expires_at) {
        if (new Date() > new Date(user.token_expires_at)) {
          Object.assign(user, null)
        }
      }
    }
  }

  function logout() {
    localStorage.removeItem('user')

    Object.keys(user).forEach((key) => {
      delete user[key]
    })
  }

  return {
    user,
    is_logged,
    update,
    restore,
    logout
  }
})
