// Wrappers
import StoreWrapper from './helpers/wrappers/store'
import GetWrapper from './helpers/wrappers/get'

// Services
import AppointmentService from '@/services/appointment'

export default {
  async events(args = {}) {
    const { success, events, pagination } = await GetWrapper(
      new AppointmentService().events,
      args
    )

    return {
      success,
      records: events || [],
      pagination
    }
  },

  async spaces(args = {}) {
    const { success, spaces } = await GetWrapper(
      new AppointmentService().spaces,
      args
    )

    return {
      success,
      records: spaces || []
    }
  },

  async appointments(args = {}) {
    const { success, pagination, appointments } = await GetWrapper(
      new AppointmentService().appointments,
      args
    )

    return {
      success,
      pagination,
      records: appointments || []
    }
  },

  async store({ $form, append = {} }) {
    return await StoreWrapper(new AppointmentService().store, {
      $form,
      append
    })
  },

  async confirm({ id }) {
    return await StoreWrapper(new AppointmentService().confirm, {
      append: { id }
    })
  },

  async cancel({ id, $form }) {
    return await StoreWrapper(new AppointmentService().cancel, {
      $form,
      append: { id }
    })
  }
}
