<template>
  <div class="container !max-w-[480px] users">
    <title_component :size="'large'">
      <template #title> {{ gt('Profile management') }} </template>
      <template #subtitle>
        {{ gt('Update your profile information and delete if you need it.') }}
      </template>
    </title_component>

    <alert_component v-if="loading" :loader="true">
      <template #title> {{ gt('Wait some seconds...') }} </template>
      <template #message>
        {{ gt('We are finding your information.') }}
      </template>
    </alert_component>

    <form
      class="flex flex-col gap-4"
      v-if="!loading && user"
      @submit.prevent="submit()"
    >
      <div class="flex flex-col gap-2">
        <p class="text-slate-500 font-medium">{{ gt('Basic Information') }}</p>
        <div class="flex gap-2">
          <input_component
            :settings="form.inputs.name"
            @update:value="form.inputs.name.value = $event"
          />
        </div>
        <div class="flex gap-2">
          <input_component
            :settings="form.inputs.phone"
            @update:value="form.inputs.phone.value = $event"
          />
        </div>
        <div class="flex gap-2">
          <input_component
            :settings="form.inputs.language"
            @update:value="form.inputs.language.value = $event"
          />
        </div>
      </div>

      <div class="flex flex-col gap-2">
        <p class="text-slate-500 font-medium">
          {{ gt('Change your password') }}
        </p>
        <div class="flex flex-col gap-2">
          <input_component
            :settings="form.inputs.password"
            @update:value="form.inputs.password.value = $event"
          />
        </div>
      </div>

      <div class="flex gap-2">
        <button_component
          :classes="['btn', 'btn-dark', 'w-fit']"
          :loading="form.is_loading"
          :submittable="true"
        >
          <i class="fi fi-rr-check"></i>
          {{ gt('Save') }}
        </button_component>

        <button_component
          :classes="['btn', 'btn-danger', 'w-fit']"
          @click="delete_my_account()"
        >
          <i class="fi fi-rr-trash"></i>
          {{ gt('Delete my account') }}
        </button_component>
      </div>
    </form>

    <alert_component v-if="!loading && !user">
      <template #title> {{ gt('Hmmm...') }} </template>
      <template #message>
        {{ gt('We could not find your information') }}
      </template>
    </alert_component>
  </div>
</template>
<script setup>
  // ENV Variables
  import ENV from '@/../env.json'

  // Components
  import { defineAsyncComponent, ref, onBeforeMount, onMounted } from 'vue'
  import UserService from '@/methods/for_users'

  const title_component = defineAsyncComponent(
    () => import('@/components/commons/title.vue')
  )

  const alert_component = defineAsyncComponent(
    () => import('@/components/commons/alert.vue')
  )

  const input_component = defineAsyncComponent(
    () => import('@/components/commons/input.vue')
  )

  const button_component = defineAsyncComponent(
    () => import('@/components/commons/button.vue')
  )

  // Helpers
  import url from '@/helpers/url'
  import get_form_state from '@/helpers/get_form_state'
  import redirect_if_necessary from '@/helpers/redirect_if_necessary'

  // Translation needs
  import { useI18n } from 'vue-i18n'
  import get_translation from '@/helpers/get_translation'
  const { t } = useI18n()
  const gt = (text, args) => get_translation(t, text, args)

  // Stores
  import UserStore from '@/stores/user'
  import AppStore from '@/stores/app'
  import BottomNavigationStore from '@/stores/bottom_navigation'
  import TopNavigationStore from '@/stores/top_navigation'

  // Variables
  const ALERT_LEVELS = ENV.enums.AlertLevels

  const User = UserStore()
  const App = AppStore()
  const BottomNavigation = BottomNavigationStore()
  const TopNavigation = TopNavigationStore()

  const { is_logged, user: user_logged } = User
  const loading = ref(true)
  const user = ref(null)
  const form = get_form_state('profile', [
    {
      label: gt('Name'),
      name: 'name',
      type: 'text'
    },
    {
      label: gt('New password'),
      name: 'password',
      type: 'password'
    },
    {
      label: gt('Phone'),
      name: 'phone',
      type: 'text',
      description: gt('Please add your country code as well e.g. +11234567890')
    },
    {
      label: gt('What is your preferred language'),
      name: 'language',
      type: 'select',
      options: [
        { id: 'en', value: gt('English') },
        { id: 'es', value: gt('Spanish') }
      ],
      default: 'en',
      value: 'en'
    }
  ])

  // Methods
  const submit = async function () {
    const { success } = await UserService.update({
      $form: form
    })

    if (success) {
      App.notification({
        title: gt('Success'),
        message: gt('Your information has been updated.'),
        level: ALERT_LEVELS.SUCCESS
      })

      setTimeout(() => {
        redirect_if_necessary(null)
      }, 3000)
    }
  }

  const delete_my_account = async function () {
    if (!confirm(gt('Are you sure you want to delete your account?'))) {
      return
    }

    const { success } = await UserService.delete({
      id: user_logged.id
    })

    if (success) {
      User.logout()
      window.location.href = url({ path: '/logout' })
    } else {
      App.notification({
        title: gt('Error'),
        message: gt('We could not delete your account.'),
        level: ALERT_LEVELS.DANGER
      })
    }
  }

  const get_user_information = async () => {
    if (!is_logged) {
      return
    }

    loading.value = true

    const { success, records } = await UserService.get({
      specific: true,
      with_properties: ['phone', 'avatar', 'language'],
      filters: {
        id: user_logged.id
      }
    })

    if (!success) {
      App.notification({
        title: gt('Error'),
        message: gt('We could not find your information.'),
        level: ALERT_LEVELS.DANGER
      })

      return
    }

    user.value = records.length > 0 ? records[0] : null

    form.inputs.name.value = user.value.name
    form.inputs.phone.value = user.value.phone
    form.inputs.language.value = user.value.language
    form.inputs.name.default = user.value.name
    form.inputs.phone.default = user.value.phone
    form.inputs.language.default = user.value.language

    loading.value = false
  }

  // Lifecycle
  onBeforeMount(() => {
    if (!is_logged) {
      setTimeout(() => {
        window.location.href = url({
          path: '/login',
          params: {
            redirect_to: window.location.pathname + window.location.search
          }
        })
      }, 3000)
    }
  })

  onMounted(() => {
    TopNavigation.title = gt('Profile Settings')
    TopNavigation.go_back = url({ path: '/journey' })
    BottomNavigation.visible = true
    get_user_information()
  })
</script>
<style lang="scss" scoped>
  @use './styles/index.scss';
</style>
