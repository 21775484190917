// Styles
import 'animate.css'

// Libs
import { createApp } from 'vue'
import { register as registerSwiper } from 'swiper/element/bundle'
import { createPinia } from 'pinia'
import { createI18n } from 'vue-i18n'

import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import ENV from '@/../env.json'

//
registerSwiper()

// Setup Echo
window.Pusher = Pusher
window.Echo = new Echo({
  broadcaster: 'pusher',
  key: ENV.reverb.key,
  wsHost: ENV.reverb.wsHost,
  wsPort: ENV.reverb.wsPort,
  wssPort: ENV.reverb.wssPort || ENV.reverb.wsPort,
  forceTLS: ENV.reverb.forceTLS || false,
  enabledTransports: ['ws', 'wss'],
  cluster: 'mt1'
})

// Helpers
import get_and_store_utm_variables from '@/helpers/get_and_store_utm_variables'
import get_and_store_user_session from '@/helpers/get_and_store_user_session'
import get_locale from '@/helpers/get_locale'

get_and_store_utm_variables()
get_and_store_user_session()

// Import translations
import en_translations from '@/locales/en.json'
import es_translations from '@/locales/es.json'

// App components
import post_button_likes from '@/components/app/posts/buttons/likes.vue'
import post_button_play from '@/components/app/posts/buttons/play.vue'

// Common components
import shortener_component from '@/components/commons/shortener.vue'

// Template components
import header_component from '@/components/template/web/header.vue'
import footer_component from '@/components/template/web/footer.vue'
import navigation_component from '@/components/template/web/navigation.vue'

// Routes
import routes_form_with_route_filtering from '@/pages/routes/components/form_with_route_filtering.vue'

// Index
import index_page from '@/pages/index/index.vue'

// Routes
import route_page from '@/pages/route/index.vue'

// Profiles
import profile_page from '@/pages/profile/index.vue'

// Blog
import blogs_form_with_posts_filtering from '@/pages/blog/components/blogs_form_with_posts_filtering.vue'

// Register
import register_form_to_register from '@/pages/register/components/form_to_register.vue'

// Register
import recover_form_to_recover from '@/pages/recover/components/form_to_recover.vue'

// Notification
import notifications_form_to_remove_notifications from '@/pages/notifications/components/form_to_remove_notifications.vue'

// Bye
import bye_form_to_delete_account from '@/pages/bye/components/form_to_delete_account.vue'

// Login
import login_form_to_login from '@/pages/login/components/form_to_login.vue'

// Journey
import journey_page from '@/pages/journey/index.vue'
import plan_page from '@/pages/plan/index.vue'

// Journey
import appointments_page from '@/pages/appointments/index.vue'
import appointment_page from '@/pages/appointment/index.vue'

// Create i18n instance
const i18n = createI18n({
  legacy: false,
  locale: get_locale(),
  fallbackLocale: 'en',
  messages: {
    en: en_translations,
    es: es_translations
  }
})

const app = createApp({})
const pinia = createPinia()

app.use(pinia)
app.use(i18n)

app.component('shortener_component', shortener_component)

// Template
app.component('header_component', header_component)
app.component('footer_component', footer_component)
app.component('navigation_component', navigation_component)

// Journey
app.component('journey_page', journey_page)
app.component('plan_page', plan_page)

// Appointment
app.component('appointments_page', appointments_page)
app.component('appointment_page', appointment_page)

// Index
app.component('index_page', index_page)

// Routes
app.component(
  'routes_form_with_route_filtering',
  routes_form_with_route_filtering
)

// Route
app.component('route_page', route_page)

// Profile
app.component('profile_page', profile_page)

// Blog
app.component(
  'blogs_form_with_posts_filtering',
  blogs_form_with_posts_filtering
)
app.component('post_button_likes', post_button_likes)
app.component('post_button_play', post_button_play)

// Register
app.component('register_form_to_register', register_form_to_register)

// Recover password
app.component('recover_form_to_recover', recover_form_to_recover)

// Login
app.component('login_form_to_login', login_form_to_login)

// Notification
app.component(
  'notifications_form_to_remove_notifications',
  notifications_form_to_remove_notifications
)

// Bye
app.component('bye_form_to_delete_account', bye_form_to_delete_account)

// Stores
// Restore user session
import UserStore from '@/stores/user'
UserStore().restore()

app.mount('#app')