import url from '@/helpers/url'

export default async function (default_path = null) {
  const params = new URLSearchParams(window.location.search)
  const param_redirect = params.get('redirect')
  const param_redirect_to = params.get('redirect_to')

  if (param_redirect || param_redirect_to) {
    window.location.href = url({
      path: param_redirect || param_redirect_to
    })

    return
  }

  if (default_path) {
    window.location.href = url({
      path: default_path
    })

    return
  }

  return
}
