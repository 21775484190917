<template>
  <form
    :class="[...(!clean ? ['card', 'p-4'] : [])]"
    @submit.prevent="submit()"
  >
    <div class="w-full flex flex-col gap-2">
      <input_component
        :settings="form.inputs.email"
        @update:value="form.inputs.email.value = $event"
      />

      <input_component
        v-if="token"
        :settings="form.inputs.password"
        @update:value="form.inputs.password.value = $event"
      />

      <div v-if="form.error" class="alert alert-light">
        <i class="fi fi-rr-info"></i>
        {{ form.error }}
      </div>

      <button_component
        :classes="['btn', 'btn-dark', 'grow']"
        :loading="form.is_loading"
        :submittable="true"
      >
        <i class="fi fi-rr-sign-in-alt"></i>
        {{ token ? gt('Change Password') : gt('Recover Password') }}
      </button_component>
    </div>
  </form>
</template>

<script setup>
  // ENV Variables
  import ENV from '@/../env.json'

  // Components
  import { defineAsyncComponent } from 'vue'

  const button_component = defineAsyncComponent(
    () => import('@/components/commons/button.vue')
  )

  const input_component = defineAsyncComponent(
    () => import('@/components/commons/input.vue')
  )

  // Stores
  import UserStore from '@/stores/user'

  // Helpers
  import get_form_state from '@/helpers/get_form_state'

  // Translation needs
  import { useI18n } from 'vue-i18n'
  import get_translation from '@/helpers/get_translation'
  const { t } = useI18n()
  const gt = (text, args) => get_translation(t, text, args)

  // Libs
  import { toRefs } from 'vue'
  import User from '@/methods/for_users'

  // Stores
  import AppStore from '@/stores/app'

  // Props
  const props = defineProps({
    clean: {
      type: Boolean,
      default: false
    },

    on_response: {
      default: null
    }
  })

  // Variables
  const App = AppStore()
  const query_params = new URLSearchParams(window.location.search)
  const token = query_params.get('token')
  const query_email = query_params.get('email')

  const { on_response } = toRefs(props)
  const form = get_form_state('login', [
    {
      label: gt('Email'),
      name: 'email',
      type: 'text',
      default: query_email,
      value: query_email
    },
    {
      label: gt('Password'),
      name: 'password',
      type: 'password',
      icon: {
        name: 'fi-rr-eye',
        onclick: {
          callback: () => {
            form.inputs.password.type =
              form.inputs.password.type === 'password' ? 'text' : 'password'
          }
        }
      }
    }
  ])

  const submit = async () => {
    const { success, errors, message, user } = await User.recover_password({
      $form: form,
      append: {
        token
      }
    })

    if (success && user) {
      UserStore().update(user)
    }

    App.notification({
      level: success
        ? ENV.enums.AlertLevels.SUCCESS
        : ENV.enums.AlertLevels.ERROR,
      title: gt('Hey!'),
      message
    })

    if (on_response.value) {
      on_response.value({ success, errors, message, user })
    }

    form.is_loading = false
  }
</script>
