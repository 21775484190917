<template>
  <div class="container !max-w-[480px] appointments">
    <title_component :size="'large'">
      <template #title> {{ gt('Appointment') }} </template>
      <template #subtitle>
        {{ gt('Confirm or cancel an appointment') }}
      </template>
    </title_component>

    <alert_component v-if="loading" :loader="true">
      <template #title> {{ gt('Wait some seconds...') }} </template>
      <template #message>
        {{ gt('We are finding your appointments.') }}
      </template>
    </alert_component>

    <appointment_component
      v-if="!loading && appointment"
      :appointment="appointment"
      :on_confirm="on_confirm"
      :on_cancel="on_cancel"
    />

    <p class="text-slate-500">
      {{
        gt(
          'Confirm or cancel your appointment, it is important for us to know if we can count on you on the day of your scheduled appointment.'
        )
      }}
    </p>

    <alert_component v-if="!loading && !appointment">
      <template #title> {{ gt('Hmmm...') }} </template>
      <template #message>
        {{ gt('We could not find your appointment') }}
      </template>
    </alert_component>
  </div>
</template>
<script setup>
  // ENV Variables
  import ENV from '@/../env.json'

  // Components
  import { defineAsyncComponent, ref, onBeforeMount, onMounted } from 'vue'
  import Appointment from '@/methods/for_appointments'

  const title_component = defineAsyncComponent(
    () => import('@/components/commons/title.vue')
  )

  const appointment_component = defineAsyncComponent(
    () => import('@/components/app/appointment/index.vue')
  )

  const alert_component = defineAsyncComponent(
    () => import('@/components/commons/alert.vue')
  )

  // Helpers
  import url from '@/helpers/url'

  // Translation needs
  import { useI18n } from 'vue-i18n'
  import get_translation from '@/helpers/get_translation'
  const { t } = useI18n()
  const gt = (text, args) => get_translation(t, text, args)

  // Stores
  import UserStore from '@/stores/user'
  import AppStore from '@/stores/app'
  import BottomNavigationStore from '@/stores/bottom_navigation'
  import TopNavigationStore from '@/stores/top_navigation'

  // Variables
  const ALERT_LEVELS = ENV.enums.AlertLevels

  const User = UserStore()
  const App = AppStore()
  const BottomNavigation = BottomNavigationStore()
  const TopNavigation = TopNavigationStore()

  const { is_logged } = User
  const query_params = new URLSearchParams(window.location.search)
  const appointment_id = ref(query_params.get('appointment_id') || null)
  const user_id = ref(query_params.get('user_id') || null)
  const authorization = ref(query_params.get('authorization') || null)
  const appointment = ref(null)
  const loading = ref(true)

  // Methods
  const get_appointment_by_id = async (args = {}) => {
    if (!is_logged) {
      return
    }

    loading.value = true

    args.with_relations = ['host', 'guest']
    args.with_properties = ['status', 'meeting_channel']
    args.order_by = 'start_at|desc'

    args.filters = {
      id: appointment_id.value
    }

    const { records } = await Appointment.appointments(args)

    appointment.value = records.length > 0 ? records[0] : null
    loading.value = false
  }

  const on_confirm = () => {
    App.notification({
      title: gt('Appointment confirmed'),
      message: gt(
        'Your appointment has been confirmed, you will be redirected.'
      ),
      level: ALERT_LEVELS.SUCCESS
    })
  }

  const on_cancel = () => {
    App.notification({
      title: gt('Appointment canceled'),
      message: gt(
        'Your appointment has been canceled, you will be redirected.'
      ),
      level: ALERT_LEVELS.LIGHT
    })
  }

  // Lifecycle
  onBeforeMount(() => {
    if (!is_logged) {
      App.notification({
        title: gt('Unauthorized'),
        message: gt(
          'You need to be logged in to confirm an appointment, you will be redirected to the login page in some seconds.'
        )
      })

      setTimeout(() => {
        window.location.href = url({
          path: '/login',
          params: {
            redirect_to: `/appointment/?appointment_id=${appointment_id.value}`,
            user_id: user_id.value,
            authorization: authorization.value
          }
        })
      }, 3000)
    }
  })

  onMounted(() => {
    TopNavigation.title = gt('Appointment')
    TopNavigation.go_back = url({ path: is_logged ? '/journey' : '/' })

    if (is_logged) {
      BottomNavigation.visible = true
      BottomNavigation.active_menu = 'journey'
    }

    get_appointment_by_id()
  })
</script>
<style lang="scss" scoped>
  @use './styles/index.scss';
</style>
