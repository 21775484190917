<template>
  <div class="page-index">
    <div class="container !max-w-[640px]">
      <div class="block">
        <span class="hey">
          <span class="left-side">
            {{ gt('Hey') }}
          </span>

          <span class="message">
            {{ gt('Struggling with insomnia?') }}
          </span>
        </span>
      </div>

      <div class="block">
        <h1 class="title">
          {{ gt('Get a personalized plan from day one') }}
        </h1>
      </div>

      <div class="block">
        <p class="subtitle">
          {{
            gt(
              'We will help you manage overthinking, insomnia, and anxiety symptoms with a tailored approach guided by a certified psychologist'
            )
          }}
        </p>
      </div>

      <div class="buttons">
        <a
          :href="url({ path: '/register' })"
          :title="gt('Get your plan today')"
          class="btn btn-lg btn-dark flex gap-2"
        >
          <i class="fi fi-rr-journey text-xl leading-[0]"></i>
          {{ gt('Get your plan today') }}
        </a>
      </div>
    </div>
  </div>
</template>
<script setup>
  // Helpers
  import url from '@/helpers/url'

  // Translation needs
  import { useI18n } from 'vue-i18n'
  import get_translation from '@/helpers/get_translation'
  const { t } = useI18n()
  const gt = (text, args) => get_translation(t, text, args)
</script>
